import {isNotQualified} from '../../../../../../modules/is-webp-qualified';

const imageConfig = {
  devices: [
    {maxWidth: 320, src: `${isNotQualified ? require('../images/cross-platform-mb-1x.png') : require('../images/cross-platform-mb-1x.webp')}`},
    {maxWidth: 480, src: `${isNotQualified ? require('../images/cross-platform-mb-2x.png') : require('../images/cross-platform-mb-2x.webp')}`},
    {maxWidth: 568, src: `${isNotQualified ? require('../images/cross-platform-mb-3x.png') : require('../images/cross-platform-mb-3x.webp')}`},
    {maxWidth: 640, src: `${isNotQualified ? require('../images/cross-platform-dt-1x.png') : require('../images/cross-platform-dt-1x.webp')}`},
    {maxWidth: 768, src: `${isNotQualified ? require('../images/cross-platform-dt-2x.png') : require('../images/cross-platform-dt-2x.webp')}`},
    {maxWidth: 1024, src: `${isNotQualified ? require('../images/cross-platform-dt-3x.png') : require('../images/cross-platform-dt-3x.webp')}`},
    {maxWidth: 3000, src: `${isNotQualified ? require('../images/cross-platform-dt-4x.png') : require('../images/cross-platform-dt-4x.webp')}`}
  ],
  popularTitles: [
    {maxWidth: 320, src: `${isNotQualified ? require('../images/image-popular-titles-mb-v3-1x.png') : require('../images/image-popular-titles-mb-v3-1x.webp')}`},
    {maxWidth: 480, src: `${isNotQualified ? require('../images/image-popular-titles-mb-v3-2x.png') : require('../images/image-popular-titles-mb-v3-2x.webp')}`},
    {maxWidth: 568, src: `${isNotQualified ? require('../images/image-popular-titles-mb-v3-3x.png') : require('../images/image-popular-titles-mb-v3-3x.webp')}`},
    {maxWidth: 640, src: `${isNotQualified ? require('../images/image-popular-titles-dt-v3-1x.png') : require('../images/image-popular-titles-dt-v3-1x.webp')}`},
    {maxWidth: 768, src: `${isNotQualified ? require('../images/image-popular-titles-dt-v3-2x.png') : require('../images/image-popular-titles-dt-v3-2x.webp')}`},
    {maxWidth: 1024, src: `${isNotQualified ? require('../images/image-popular-titles-dt-v3-3x.png') : require('../images/image-popular-titles-dt-v3-3x.webp')}`},
    {maxWidth: 3000, src: `${isNotQualified ? require('../images/image-popular-titles-dt-v3-4x.png') : require('../images/image-popular-titles-dt-v3-4x.webp')}`}
  ],
  unlimitedAccess: [
    {maxWidth: 320, src: `${isNotQualified ? require('../images/image-unlimited-access-mb-1x.jpg') : require('../images/image-unlimited-access-mb-1x.webp')}`},
    {maxWidth: 480, src: `${isNotQualified ? require('../images/image-unlimited-access-mb-2x.jpg') : require('../images/image-unlimited-access-mb-2x.webp')}`},
    {maxWidth: 568, src: `${isNotQualified ? require('../images/image-unlimited-access-mb-3x.jpg') : require('../images/image-unlimited-access-mb-3x.webp')}`},
    {maxWidth: 640, src: `${isNotQualified ? require('../images/image-unlimited-access-dt-1x.jpg') : require('../images/image-unlimited-access-dt-1x.webp')}`},
    {maxWidth: 768, src: `${isNotQualified ? require('../images/image-unlimited-access-dt-2x.jpg') : require('../images/image-unlimited-access-dt-2x.webp')}`},
    {maxWidth: 1024, src: `${isNotQualified ? require('../images/image-unlimited-access-dt-3x.jpg') : require('../images/image-unlimited-access-dt-3x.webp')}`},
    {maxWidth: 3000, src: `${isNotQualified ? require('../images/image-unlimited-access-dt-4x.jpg') : require('../images/image-unlimited-access-dt-4x.webp')}`}
  ],
  motherDaughter: [
    {maxWidth: 320, src: `${isNotQualified ? require('../images/image-mother-daughter-mb-1x.jpg') : require('../images/image-mother-daughter-mb-1x.webp')}`},
    {maxWidth: 480, src: `${isNotQualified ? require('../images/image-mother-daughter-mb-2x.jpg') : require('../images/image-mother-daughter-mb-2x.webp')}`},
    {maxWidth: 568, src: `${isNotQualified ? require('../images/image-mother-daughter-mb-3x.jpg') : require('../images/image-mother-daughter-mb-3x.webp')}`},
    {maxWidth: 640, src: `${isNotQualified ? require('../images/image-mother-daughter-dt-1x.jpg') : require('../images/image-mother-daughter-dt-1x.webp')}`},
    {maxWidth: 768, src: `${isNotQualified ? require('../images/image-mother-daughter-dt-2x.jpg') : require('../images/image-mother-daughter-dt-2x.webp')}`},
    {maxWidth: 1024, src: `${isNotQualified ? require('../images/image-mother-daughter-dt-3x.jpg') : require('../images/image-mother-daughter-dt-3x.webp')}`},
    {maxWidth: 3000, src: `${isNotQualified ? require('../images/image-mother-daughter-dt-4x.jpg') : require('../images/image-mother-daughter-dt-4x.webp')}`}
  ],
  trackMonitor: [
    {maxWidth: 320, src: `${isNotQualified ? require('../images/image-track-monitor-mb-v3-1x.png') : require('../images/image-track-monitor-mb-v3-1x.webp')}`},
    {maxWidth: 480, src: `${isNotQualified ? require('../images/image-track-monitor-mb-v3-2x.png') : require('../images/image-track-monitor-mb-v3-2x.webp')}`},
    {maxWidth: 568, src: `${isNotQualified ? require('../images/image-track-monitor-mb-v3-3x.png') : require('../images/image-track-monitor-mb-v3-3x.webp')}`},
    {maxWidth: 640, src: `${isNotQualified ? require('../images/image-track-monitor-dt-v3-1x.png') : require('../images/image-track-monitor-dt-v3-1x.webp')}`},
    {maxWidth: 768, src: `${isNotQualified ? require('../images/image-track-monitor-dt-v3-2x.png') : require('../images/image-track-monitor-dt-v3-2x.webp')}`},
    {maxWidth: 1024, src: `${isNotQualified ? require('../images/image-track-monitor-dt-v3-3x.png') : require('../images/image-track-monitor-dt-v3-3x.webp')}`},
    {maxWidth: 3000, src: `${isNotQualified ? require('../images/image-track-monitor-dt-v3-4x.png') : require('../images/image-track-monitor-dt-v3-4x.webp')}`}
  ],
  awardWinning: [
    {maxWidth: 320, src: `${isNotQualified ? require('../images/image-award-winning-mb-v2-1x.png') : require('../images/image-award-winning-mb-v2-1x.webp')}`},
    {maxWidth: 480, src: `${isNotQualified ? require('../images/image-award-winning-mb-v2-2x.png') : require('../images/image-award-winning-mb-v2-2x.webp')}`},
    {maxWidth: 568, src: `${isNotQualified ? require('../images/image-award-winning-mb-v2-3x.png') : require('../images/image-award-winning-mb-v2-3x.webp')}`},
    {maxWidth: 640, src: `${isNotQualified ? require('../images/image-award-winning-dt-v2-1x.png') : require('../images/image-award-winning-dt-v2-1x.webp')}`},
    {maxWidth: 768, src: `${isNotQualified ? require('../images/image-award-winning-dt-v2-2x.png') : require('../images/image-award-winning-dt-v2-2x.webp')}`},
    {maxWidth: 1024, src: `${isNotQualified ? require('../images/image-award-winning-dt-v2-3x.png') : require('../images/image-award-winning-dt-v2-3x.webp')}`},
    {maxWidth: 3000, src: `${isNotQualified ? require('../images/image-award-winning-dt-v2-4x.png') : require('../images/image-award-winning-dt-v2-4x.webp')}`}
  ],
  childSafe: [
    {maxWidth: 568, src: `${isNotQualified ? require('../images/kids-together-1x.png') : require('../images/kids-together-1x.webp')}`},
    {maxWidth: 1024, src: `${isNotQualified ? require('../images/kids-together-2x.png') : require('../images/kids-together-2x.webp')}`},
  ],
  growingCollection: [
    {maxWidth: 320, src: `${isNotQualified ? require('../images/image-growing-collection-mb-v3-1x.png') : require('../images/image-growing-collection-mb-v3-1x.webp')}`},
    {maxWidth: 480, src: `${isNotQualified ? require('../images/image-growing-collection-mb-v3-2x.png') : require('../images/image-growing-collection-mb-v3-2x.webp')}`},
    {maxWidth: 568, src: `${isNotQualified ? require('../images/image-growing-collection-mb-v3-3x.png') : require('../images/image-growing-collection-mb-v3-3x.webp')}`},
    {maxWidth: 640, src: `${isNotQualified ? require('../images/image-growing-collection-dt-v3-1x.png') : require('../images/image-growing-collection-dt-v3-1x.webp')}`},
    {maxWidth: 768, src: `${isNotQualified ? require('../images/image-growing-collection-dt-v3-2x.png') : require('../images/image-growing-collection-dt-v3-2x.webp')}`},
    {maxWidth: 1024, src: `${isNotQualified ? require('../images/image-growing-collection-dt-v3-3x.png') : require('../images/image-growing-collection-dt-v3-3x.webp')}`},
    {maxWidth: 3000, src: `${isNotQualified ? require('../images/image-growing-collection-dt-v3-4x.png') : require('../images/image-growing-collection-dt-v3-4x.webp')}`}
  ],
  readersIcon: [
    {maxWidth: 320, src: `${isNotQualified ? require('../images/readers-icon-mb-1x.png') : require('../images/readers-icon-mb-1x.webp')}`},
    {maxWidth: 480, src: `${isNotQualified ? require('../images/readers-icon-mb-2x.png') : require('../images/readers-icon-mb-2x.webp')}`},
    {maxWidth: 568, src: `${isNotQualified ? require('../images/readers-icon-mb-3x.png') : require('../images/readers-icon-mb-3x.webp')}`},
    {maxWidth: 640, src: `${isNotQualified ? require('../images/readers-icon-dt-1x.png') : require('../images/readers-icon-mb-3x.webp')}`},
    {maxWidth: 768, src: `${isNotQualified ? require('../images/readers-icon-dt-2x.png') : require('../images/readers-icon-dt-2x.webp')}`},
    {maxWidth: 1024, src: `${isNotQualified ? require('../images/readers-icon-dt-3x.png') : require('../images/readers-icon-dt-3x.webp')}`},
    {maxWidth: 3000, src: `${isNotQualified ? require('../images/readers-icon-dt-4x.png') : require('../images/readers-icon-dt-4x.webp')}`}
  ],
};

export {
  imageConfig
};
