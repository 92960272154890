import '@aofl/select';
import '@aofl/drawer';

export default (ctx, html) => html`
  <form id="payment-form-container" name="payment-form" @submit="${(e) => ctx.submitForm(e)}" novalidate>
    <fieldset class="cc-card-payment">
      <div class="input-container ${ctx.form.cardName.valid ? '' : 'is-invalid'}">
        <label class="cc-input-label" for="card-name">Cardholder<span class="u-br"></span> Name</label>
        <div class="input-wrapper">
          <input id="card-name" type="text" name="card-name" @blur="${(e) => ctx.validateCardName(e)}" @input="${(e) => ctx.updateCardName(e)}" value="${ctx.cardName}" autocomplete="cc-name" aria-label="Enter the cardholder name." aria-describedby="card-name-error-message" aria-invalid="${ctx.form.cardName.valid ? 'false' : 'true'}" required>
          ${ctx.form.cardName.valid ? '' : html`
            <label class="error-message" for="card-name" role="alert" aria-label="Error - Please enter a valid cardholder name.">Please enter the cardholder name.</label>
          `}
        </div>
      </div>
      <div class="input-container ${ctx.form.cardNumber.valid ? '' : 'is-invalid'}">
        <label class="cc-input-label" for="cc-num">Credit Card<span class="u-br"></span> Number</label>
        <div class="input-wrapper">
          <input id="cc-num" type="tel" @blur="${(e) => ctx.validateCardNumber(e)}" @input="${(e) => ctx.updateCardNumber(e)}" value="${ctx.cardNumber}" autocomplete="cc-number" aria-label="Enter the credit card number." aria-describedby="cc-num-error-message" aria-invalid="${ctx.form.cardNumber.valid ? 'false' : 'true'}" required>
          ${ctx.form.cardNumber.isRequired.valid ? '' : html`
            <label class="error-message" for="cc-num" role="alert" aria-label="Error - Please enter the credit card number.">Please enter the credit card number.</label>
          `}
          ${ctx.form.cardNumber.isNotZero.valid ? '' : html`
            <label class="error-message" for="cc-num" role="alert" aria-label="Error - Please enter the credit card number.">Your card number is invalid.</label>
          `}
        </div>
      </div>
      <div class="input-container ${(ctx.form.expDate.valid && ctx.form.cardCvv.valid) ? '' : 'is-invalid'}">
        <label>Expiration<span class="u-br"></span> Date</label>
        <div id="dropdown-row" class="input-wrapper">
          <div id="date-selector" class="${ctx.form.expDate.valid}">
            <div class="mkt-dropdown ${ctx.form.expDate.valid ? '' : 'is-invalid'}" id="billing-info-month">
              <div class="dropdown-wrapper">
                <div class="button-wrapper enteractive tab-outline-blue ${ctx.form.expDate.valid ? '' : 'is-invalid'}" role="listbox" @click="${(e) => ctx.toggleMonth(e)}" aria-label="Use the drop down to enter the month of the credit card expiration date." tabindex="0" aria-describedby="exp-error" aria-invalid="${ctx.form.expDate.valid ? 'false' : 'true'}">
                  <div class="display-box">
                    <span>${ctx.cardMonth}</span>
                  </div>
                  <div class="dropdown-arrow">
                    <span>&#9662;</span>
                  </div>
                </div>
                <aofl-drawer animate-drawer="false" ?open="${ctx.monthDropdownActive}">
                  <aofl-select-list @change="${(e) => ctx.updateCardMonth(e)}">
                  ${ctx.months.map((month) => html`
                    <aofl-list-option class="enteractive-list-option" value=${month} tabindex="0">${month}</aofl-list-option>
                  `)}
                </aofl-select-list>
                </aofl-drawer>
              </div>
            </div>
            <div class="mkt-dropdown" id="billing-info-year">
              <div class="dropdown-wrapper">
                <div class="button-wrapper enteractive tab-outline-blue" role="listbox" @click="${(e) => ctx.toggleYear(e)}" aria-label="Use the drop down to enter the year of the credit card expiration date." tabindex="0">
                  <div class="display-box">
                    <span>${ctx.cardYear}</span>
                  </div>
                  <div class="dropdown-arrow">
                    <span>&#9662;</span>
                  </div>
                </div>
                <aofl-drawer animate-drawer="false" ?open="${ctx.yearDropdownActive}">
                  <aofl-select-list @change="${(e) => ctx.updateCardYear(e)}">
                  ${ctx.years.map((year) => html`
                    <aofl-list-option class="enteractive-list-option" value=${year} tabindex="0">${year}</aofl-list-option>
                  `)}
                </aofl-select-list>
                </aofl-drawer>
              </div>
            </div>
          </div>
          ${ctx.form.expDate.isNotExpired.valid ? '' : html`
            <label id="exp-error" class="error-message" for="exp-date" role="alert" aria-label="Error - Your expiration date is invalid. It is a required field and must be filled in.">Your expiration date is invalid.</label>
          `}
          <div id="cvv-wrapper" class="input-container">
            <div id="cvv-error-wrapper">
              <div class="error-wrapper ${ctx.form.cardCvv.valid ? '' : 'is-invalid'}">
                <input id="cvv" type="tel" name="cvv" maxlength="4" @input="${(e) => ctx.updateCardCvv(e)}" value="${ctx.cardCvv}" @blur="${(e) => ctx.validateCardCvv(e)}" placeholder="CVV" aria-label="Enter the CVV of the credit card." aria-describedby="cvv-error-message" aria-invalid="${ctx.form.cardCvv.valid ? 'false' : 'true'}" required>
                ${ctx.form.cardCvv.isRequired.valid ? '' : html`
                <label class="error-message ${ctx.form.cardCvv.isRequired.valid}" for="ccv" role="alert" aria-label="Error - Please enter your credit card security code.">Please enter the security code.</label>
                `}
                ${ctx.form.cardCvv.isNotEmoji.valid && ctx.form.cardCvv.isNotZero.valid ? '' : html`
                <label class="error-message ${ctx.form.cardCvv.isRequired.valid}" for="ccv" role="alert" aria-label="Error - Please enter your credit card security code.">Your card security code is invalid.</label>
                `}
              </div>
              <button id="cvv-button" dom-scope="UJLWkMQg" class="button blue tab-outline-blue" type="button" @click="${(e) => ctx.showCvvPopup(e)}" aria-label="What is the CVV and where is it located on my credit card.">?</button>
            </div>
          </div>
        </div>
      </div>
      <div id="zip-code-input-container" class="input-container ${ctx.form.zipCode.valid ? '' : 'is-invalid'}">
        <label class="cc-input-label" for="zipcode">Billing Zip/<span class="u-br"></span>Postal Code</label>
        <div class="input-wrapper">
          <input id="zipcode" type="tel" name="zipcode" @blur="${(e) => ctx.validateZipCode(e)}" @input="${(e) => ctx.updateZipCode(e)}" placeholder="Gift/International Cards: enter 12345" value="${ctx.zipCode}" autocomplete="postal-code" aria-label="Enter your Billing Zip or Postal Code. For gift cards or international cards enter 12345." aria-describedby="zipcode-error-message" aria-invalid="${ctx.form.zipCode.valid ? 'false' : 'true'}" required>
          ${ctx.form.zipCode.isRequired.valid ? '' : html`
            <label class="error-message" for="zipcode" role="alert" aria-label="Error - Please provide your Billing Zip or Postal Code. For gift cards or international cards please enter 12345.">Please provide Zip or Postal Code.</label>
          `}
          ${ctx.form.zipCode.isNotZero.valid && ctx.form.zipCode.isNotEmoji.valid ? '' : html`
          <label class="error-message" for="zipcode" role="alert" aria-label="Error - Please provide your Billing Zip or Postal Code. For gift cards or international cards please enter 12345.">Zip or Postal Code format you entered is invalid.</label>
          `}
        </div>
      </div>
      <div class="input-container">
        <label class="cc-input-label" for="phone-number">Phone<span class="u-br"></span> Number</label>
        <div class="input-wrapper">
          <input id="phone-number" type="tel" @input="${(e) => ctx.updatePhoneNumber(e)}" placeholder="Optional, but recommended" value="${ctx.phoneNumber}" autocomplete="tel">
        </div>
      </div>
    </fieldset>
    <button id="payment-submit-button" dom-scope="ZF7TRMDr" type="submit" tabindex="-1"></button>
  </form>
`;
