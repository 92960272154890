import styles from './template.css';
import template from './template';
import {AoflElement, customElement} from '@aofl/element';
import {ApiService} from '../api-service';
import {routerInstance} from '@aofl/router';
import {pages} from '../constants-enumerate';
import {UserInfoService} from '../user-info-service';
import {accessibilityMixin} from '../accessibility-mixin';

/**
 * @summary LogoutButton
 * @extends {AoflElement}
 */

@customElement('logout-button')
class LogoutButton extends accessibilityMixin(AoflElement) {
  /**
   * Creates an instance of LogoutButton.
   */
  constructor() {
    super();
  }

  /**
   * @readonly
   */
  static is = 'logout-button';

  /**
   * @private
   * @param {Event} e
   */
  async clickHandler(e) {
    e.preventDefault();
    try {
      await ApiService.logout();
      UserInfoService.clear();
      routerInstance.navigate(pages.HOME, {
        forceReload: true
      });
    } catch (e) {}
  }
  /**
   *
   */
  connectedCallback() {
    super.connectedCallback();
    this.addEventListener('click', this.clickHandler);
  }
  /**
   *
   * @return {Object}
   */
  render() {
    return super.render(template, [styles]);
  }
  /**
   *
   */
  disconnectedCallback() {
    this.removeEventListener('click', this.clickHandler);
    super.disconnectedCallback();
  }
}

export default LogoutButton;
