import '@aofl/picture';
import {imageConfig} from './__config/images';

export default (ctx, html) => html`
  <footer>
    <aofl-picture>
      ${imageConfig.riqLogo.map((image) => html`
        <aofl-source media="(max-width: ${image.maxWidth}px)" srcset="${image.src}"></aofl-source>
      `)}
      <aofl-img src="${require('./images/RIQ-logo-footer-dt-4x.png')}" width="290" height="72" alt=""></aofl-img>
    </aofl-picture>
    <slot></slot>
  </footer>
`;
