import {isNotQualified} from '../../../../modules/is-webp-qualified';

const imageConfig = {
  librarian: [
    {maxWidth: 320, src: `${isNotQualified ? require('../images/librarian-img-mobile.png') : require('../images/librarian-img-mobile.png')}`, width: 613, height: 639},
    {maxWidth: 480, src: `${isNotQualified ? require('../images/librarian-img-mobile.png') : require('../images/librarian-img-mobile.png')}`, width: 613, height: 639},
    {maxWidth: 568, src: `${isNotQualified ? require('../images/librarian-img-mobile.png') : require('../images/librarian-img-mobile.png')}`, width: 613, height: 639},
    {maxWidth: 640, src: `${isNotQualified ? require('../images/librarian-img-dt-1x.png') : require('../images/librarian-img-dt-1x.webp')}`, width: 538, height: 397},
    {maxWidth: 768, src: `${isNotQualified ? require('../images/librarian-img-dt-2x.png') : require('../images/librarian-img-dt-2x.webp')}`, width: 650, height: 480},
    {maxWidth: 1024, src: `${isNotQualified ? require('../images/librarian-img-dt-3x.png') : require('../images/librarian-img-dt-3x.webp')}`, width: 864, height: 638},
    {maxWidth: 3000, src: `${isNotQualified ? require('../images/librarian-img-dt-4x.png') : require('../images/librarian-img-dt-4x.webp')}`, width: 1016, height: 750}
  ],
  sparks: [
    {maxWidth: 640, src: `${isNotQualified ? require('../images/librarian-img-mobile.png') : require('../images/librarian-img-mobile.png')}`, width: 613, height: 639},
    {maxWidth: 768, src: `${isNotQualified ? require('../images/librarian-img-dt-2x.png') : require('../images/librarian-img-dt-2x.png')}`, width: 650, height: 480},
    {maxWidth: 1024, src: `${isNotQualified ? require('../images/librarian-img-dt-3x.png') : require('../images/librarian-img-dt-3x.png')}`, width: 864, height: 638},
    {maxWidth: 3000, src: `${isNotQualified ? require('../images/librarian-img-dt-4x.png') : require('../images/librarian-img-dt-4x.png')}`, width: 1016, height: 750}
  ],
  featuresBox: [
    {maxWidth: 320, src: `${isNotQualified ? require('../images/features-box-mb-v6-1x.png') : require('../images/features-box-mb-v6-1x.webp')}`},
    {maxWidth: 480, src: `${isNotQualified ? require('../images/features-box-mb-v6-2x.png') : require('../images/features-box-mb-v6-2x.webp')}`},
    {maxWidth: 568, src: `${isNotQualified ? require('../images/features-box-mb-v6-3x.png') : require('../images/features-box-mb-v6-3x.webp')}`},
    {maxWidth: 640, src: `${isNotQualified ? require('../images/features-box-dt-v6-1x.png') : require('../images/features-box-dt-v6-1x.webp')}`},
    {maxWidth: 768, src: `${isNotQualified ? require('../images/features-box-dt-v6-2x.png') : require('../images/features-box-dt-v6-2x.webp')}`},
    {maxWidth: 1024, src: `${isNotQualified ? require('../images/features-box-dt-v6-3x.png') : require('../images/features-box-dt-v6-3x.webp')}`},
    {maxWidth: 3000, src: `${isNotQualified ? require('../images/features-box-dt-v6-4x.png') : require('../images/features-box-dt-v6-4x.webp')}`}
  ],
  featuredPublishers: [
    {maxWidth: 320, src: `${isNotQualified ? require('../images/featured-publishers-removed-mb-v2-1x.png') : require('../images/featured-publishers-removed-mb-v2-1x.webp')}`, alt: 'Disney, Pixar, Star Wars, Marvel, Curious George, Highlights, The Boxcar Children, digital books for kids'},
    {maxWidth: 480, src: `${isNotQualified ? require('../images/featured-publishers-removed-mb-v2-2x.png') : require('../images/featured-publishers-removed-mb-v2-2x.webp')}`, alt: 'Disney, Pixar, Star Wars, Marvel, Curious George, Highlights, The Boxcar Children, digital books for kids'},
    {maxWidth: 568, src: `${isNotQualified ? require('../images/featured-publishers-removed-mb-v2-3x.png') : require('../images/featured-publishers-removed-mb-v2-3x.webp')}`, alt: 'Disney, Pixar, Star Wars, Marvel, Curious George, Highlights, The Boxcar Children, digital books for kids'},
    {maxWidth: 640, src: `${isNotQualified ? require('../images/featured-publishers-removed-dt-v2-1x.png') : require('../images/featured-publishers-removed-dt-v2-1x.webp')}`, alt: 'Disney, Pixar, Star Wars, Marvel, Curious George, Highlights, The Boxcar Children, digital books for kids'},
    {maxWidth: 768, src: `${isNotQualified ? require('../images/featured-publishers-removed-dt-v2-2x.png') : require('../images/featured-publishers-removed-dt-v2-2x.webp')}`, alt: 'Disney, Pixar, Star Wars, Marvel, Curious George, Highlights, The Boxcar Children, digital books for kids'},
    {maxWidth: 1024, src: `${isNotQualified ? require('../images/featured-publishers-removed-dt-v2-3x.png') : require('../images/featured-publishers-removed-dt-v2-3x.webp')}`, alt: 'Disney, Pixar, Star Wars, Marvel, Curious George, Highlights, The Boxcar Children, digital books for kids'},
    {maxWidth: 3000, src: `${isNotQualified ? require('../images/featured-publishers-removed-dt-v2-4x.png') : require('../images/featured-publishers-removed-dt-v2-4x.webp')}`, alt: 'Disney, Pixar, Star Wars, Marvel, Curious George, Highlights, The Boxcar Children, digital books for kids'}
  ],
  pre: [
    {maxWidth: 320, src: `${isNotQualified ? require('../images/books-carousel-prereaders-mb-1x.png') : require('../images/books-carousel-prereaders-mb-1x.webp')}`, alt: 'ABC, Farm, Curious George, Big Bug, Little Bug, The Tortoise and the Hare, ABCmouse, Baby, Animals, The Lion and the Mouse, digital books for kids', width: 228, height: 242},
    {maxWidth: 480, src: `${isNotQualified ? require('../images/books-carousel-prereaders-mb-2x.png') : require('../images/books-carousel-prereaders-mb-2x.webp')}`, alt: 'ABC, Farm, Curious George, Big Bug, Little Bug, The Tortoise and the Hare, ABCmouse, Baby, Animals, The Lion and the Mouse, digital books for kids', width: 366, height: 388},
    {maxWidth: 568, src: `${isNotQualified ? require('../images/books-carousel-prereaders-mb-3x.png') : require('../images/books-carousel-prereaders-mb-3x.webp')}`, alt: 'ABC, Farm, Curious George, Big Bug, Little Bug, The Tortoise and the Hare, ABCmouse, Baby, Animals, The Lion and the Mouse, digital books for kids', width: 430, height: 456},
    {maxWidth: 640, src: `${isNotQualified ? require('../images/books-carousel-prereaders-dt-1x.png') : require('../images/books-carousel-prereaders-dt-1x.webp')}`, alt: 'ABC, Farm, Curious George, Big Bug, Little Bug, The Tortoise and the Hare, ABCmouse, Baby, Animals, The Lion and the Mouse, digital books for kids', width: 647, height: 131},
    {maxWidth: 768, src: `${isNotQualified ? require('../images/books-carousel-prereaders-dt-2x.png') : require('../images/books-carousel-prereaders-dt-2x.webp')}`, alt: 'ABC, Farm, Curious George, Big Bug, Little Bug, The Tortoise and the Hare, ABCmouse, Baby, Animals, The Lion and the Mouse, digital books for kids', width: 781, height: 158},
    {maxWidth: 1024, src: `${isNotQualified ? require('../images/books-carousel-prereaders-dt-3x.png') : require('../images/books-carousel-prereaders-dt-3x.webp')}`, alt: 'ABC, Farm, Curious George, Big Bug, Little Bug, The Tortoise and the Hare, ABCmouse, Baby, Animals, The Lion and the Mouse, digital books for kids', width: 1037, height: 210},
    {maxWidth: 3000, src: `${isNotQualified ? require('../images/books-carousel-prereaders-dt-4x.png') : require('../images/books-carousel-prereaders-dt-4x.webp')}`, alt: 'ABC, Farm, Curious George, Big Bug, Little Bug, The Tortoise and the Hare, ABCmouse, Baby, Animals, The Lion and the Mouse, digital books for kids', width: 1220, height: 247}
  ],
  emerging: [
    {maxWidth: 320, src: `${isNotQualified ? require('../images/books-carousel-emerging-mb-1x.png') : require('../images/books-carousel-emerging-mb-1x.webp')}`, alt: 'Disney, Aladdin, World of Reading, Pixar, Car, Marvel, Black Panther, Star Wars, Planes, Manatees, ABCmouse, Grasshopper, Ants, Highlights, The Letter Book, Curious George, Soccer, digital books for kids', width: 228, height: 166},
    {maxWidth: 480, src: `${isNotQualified ? require('../images/books-carousel-emerging-mb-2x.png') : require('../images/books-carousel-emerging-mb-2x.webp')}`, alt: 'Disney, Aladdin, World of Reading, Pixar, Car, Marvel, Black Panther, Star Wars, Planes, Manatees, ABCmouse, Grasshopper, Ants, Highlights, The Letter Book, Curious George, Soccer, digital books for kids', width: 366, height: 267},
    {maxWidth: 568, src: `${isNotQualified ? require('../images/books-carousel-emerging-mb-3x.png') : require('../images/books-carousel-emerging-mb-3x.webp')}`, alt: 'Disney, Aladdin, World of Reading, Pixar, Car, Marvel, Black Panther, Star Wars, Planes, Manatees, ABCmouse, Grasshopper, Ants, Highlights, The Letter Book, Curious George, Soccer, digital books for kids', width: 430, height: 313},
    {maxWidth: 640, src: `${isNotQualified ? require('../images/books-carousel-emerging-dt-1x.png') : require('../images/books-carousel-emerging-dt-1x.webp')}`, alt: 'Disney, Aladdin, World of Reading, Pixar, Car, Marvel, Black Panther, Star Wars, Planes, Manatees, ABCmouse, Grasshopper, Ants, Highlights, The Letter Book, Curious George, Soccer, digital books for kids', width: 647, height: 131},
    {maxWidth: 768, src: `${isNotQualified ? require('../images/books-carousel-emerging-dt-2x.png') : require('../images/books-carousel-emerging-dt-2x.webp')}`, alt: 'Disney, Aladdin, World of Reading, Pixar, Car, Marvel, Black Panther, Star Wars, Planes, Manatees, ABCmouse, Grasshopper, Ants, Highlights, The Letter Book, Curious George, Soccer, digital books for kids', width: 781, height: 158},
    {maxWidth: 1024, src: `${isNotQualified ? require('../images/books-carousel-emerging-dt-3x.png') : require('../images/books-carousel-emerging-dt-3x.webp')}`, alt: 'Disney, Aladdin, World of Reading, Pixar, Car, Marvel, Black Panther, Star Wars, Planes, Manatees, ABCmouse, Grasshopper, Ants, Highlights, The Letter Book, Curious George, Soccer, digital books for kids', width: 1037, height: 210},
    {maxWidth: 3000, src: `${isNotQualified ? require('../images/books-carousel-emerging-dt-4x.png') : require('../images/books-carousel-emerging-dt-4x.webp')}`, alt: 'Disney, Aladdin, World of Reading, Pixar, Car, Marvel, Black Panther, Star Wars, Planes, Manatees, ABCmouse, Grasshopper, Ants, Highlights, The Letter Book, Curious George, Soccer, digital books for kids', width: 1220, height: 247}
  ],
  growing: [
    {maxWidth: 320, src: `${isNotQualified ? require('../images/books-carousel-growing-mb-1x.png') : require('../images/books-carousel-growing-mb-1x.webp')}`, alt: 'Disney 101 Dalmatians, Pixar, Toy Story, World of Reading, Marvel, Avengers, Stars Wars, Highlights, Stepped Readers, Dinosaurs, Pyramids, The Three Little Pigs, digital books for kids', width: 228, height: 166},
    {maxWidth: 480, src: `${isNotQualified ? require('../images/books-carousel-growing-mb-2x.png') : require('../images/books-carousel-growing-mb-2x.webp')}`, alt: 'Disney 101 Dalmatians, Pixar, Toy Story, World of Reading, Marvel, Avengers, Stars Wars, Highlights, Stepped Readers, Dinosaurs, Pyramids, The Three Little Pigs, digital books for kids', width: 366, height: 267},
    {maxWidth: 568, src: `${isNotQualified ? require('../images/books-carousel-growing-mb-3x.png') : require('../images/books-carousel-growing-mb-3x.webp')}`, alt: 'Disney 101 Dalmatians, Pixar, Toy Story, World of Reading, Marvel, Avengers, Stars Wars, Highlights, Stepped Readers, Dinosaurs, Pyramids, The Three Little Pigs, digital books for kids', width: 430, height: 313},
    {maxWidth: 640, src: `${isNotQualified ? require('../images/books-carousel-growing-dt-1x.png') : require('../images/books-carousel-growing-dt-1x.webp')}`, alt: 'Disney 101 Dalmatians, Pixar, Toy Story, World of Reading, Marvel, Avengers, Stars Wars, Highlights, Stepped Readers, Dinosaurs, Pyramids, The Three Little Pigs, digital books for kids', width: 647, height: 131},
    {maxWidth: 768, src: `${isNotQualified ? require('../images/books-carousel-growing-dt-2x.png') : require('../images/books-carousel-growing-dt-2x.webp')}`, alt: 'Disney 101 Dalmatians, Pixar, Toy Story, World of Reading, Marvel, Avengers, Stars Wars, Highlights, Stepped Readers, Dinosaurs, Pyramids, The Three Little Pigs, digital books for kids', width: 781, height: 158},
    {maxWidth: 1024, src: `${isNotQualified ? require('../images/books-carousel-growing-dt-3x.png') : require('../images/books-carousel-growing-dt-3x.webp')}`, alt: 'Disney 101 Dalmatians, Pixar, Toy Story, World of Reading, Marvel, Avengers, Stars Wars, Highlights, Stepped Readers, Dinosaurs, Pyramids, The Three Little Pigs, digital books for kids', width: 1037, height: 210},
    {maxWidth: 3000, src: `${isNotQualified ? require('../images/books-carousel-growing-dt-4x.png') : require('../images/books-carousel-growing-dt-4x.webp')}`, alt: 'Disney 101 Dalmatians, Pixar, Toy Story, World of Reading, Marvel, Avengers, Stars Wars, Highlights, Stepped Readers, Dinosaurs, Pyramids, The Three Little Pigs, digital books for kids', width: 1220, height: 247}
  ],
  independent: [
    {maxWidth: 320, src: `${isNotQualified ? require('../images/books-carousel-independent-mb-1x.png') : require('../images/books-carousel-independent-mb-1x.webp')}`, alt: 'Stepping Stones, Disney, Tangled, Rapunzel, Pixar, Finding Dory, Marvel, Iron Man, Space, Star Wars, Rogue One, Highlights, Basketball, Comic Book, Goldilocks, Pirates of the Caribbean, Jokes, Museum Mysteries, digital books for kids', width: 228, height: 166},
    {maxWidth: 480, src: `${isNotQualified ? require('../images/books-carousel-independent-mb-2x.png') : require('../images/books-carousel-independent-mb-2x.webp')}`, alt: 'Stepping Stones, Disney, Tangled, Rapunzel, Pixar, Finding Dory, Marvel, Iron Man, Space, Star Wars, Rogue One, Highlights, Basketball, Comic Book, Goldilocks, Pirates of the Caribbean, Jokes, Museum Mysteries, digital books for kids', width: 366, height: 267},
    {maxWidth: 568, src: `${isNotQualified ? require('../images/books-carousel-independent-mb-3x.png') : require('../images/books-carousel-independent-mb-3x.webp')}`, alt: 'Stepping Stones, Disney, Tangled, Rapunzel, Pixar, Finding Dory, Marvel, Iron Man, Space, Star Wars, Rogue One, Highlights, Basketball, Comic Book, Goldilocks, Pirates of the Caribbean, Jokes, Museum Mysteries, digital books for kids', width: 430, height: 313},
    {maxWidth: 640, src: `${isNotQualified ? require('../images/books-carousel-independent-dt-1x.png') : require('../images/books-carousel-independent-dt-1x.webp')}`, alt: 'Stepping Stones, Disney, Tangled, Rapunzel, Pixar, Finding Dory, Marvel, Iron Man, Space, Star Wars, Rogue One, Highlights, Basketball, Comic Book, Goldilocks, Pirates of the Caribbean, Jokes, Museum Mysteries, digital books for kids', width: 647, height: 131},
    {maxWidth: 768, src: `${isNotQualified ? require('../images/books-carousel-independent-dt-2x.png') : require('../images/books-carousel-independent-dt-2x.webp')}`, alt: 'Stepping Stones, Disney, Tangled, Rapunzel, Pixar, Finding Dory, Marvel, Iron Man, Space, Star Wars, Rogue One, Highlights, Basketball, Comic Book, Goldilocks, Pirates of the Caribbean, Jokes, Museum Mysteries, digital books for kids', width: 781, height: 158},
    {maxWidth: 1024, src: `${isNotQualified ? require('../images/books-carousel-independent-dt-3x.png') : require('../images/books-carousel-independent-dt-3x.webp')}`, alt: 'Stepping Stones, Disney, Tangled, Rapunzel, Pixar, Finding Dory, Marvel, Iron Man, Space, Star Wars, Rogue One, Highlights, Basketball, Comic Book, Goldilocks, Pirates of the Caribbean, Jokes, Museum Mysteries, digital books for kids', width: 1037, height: 210},
    {maxWidth: 3000, src: `${isNotQualified ? require('../images/books-carousel-independent-dt-4x.png') : require('../images/books-carousel-independent-dt-4x.webp')}`, alt: 'Stepping Stones, Disney, Tangled, Rapunzel, Pixar, Finding Dory, Marvel, Iron Man, Space, Star Wars, Rogue One, Highlights, Basketball, Comic Book, Goldilocks, Pirates of the Caribbean, Jokes, Museum Mysteries, digital books for kids', width: 1220, height: 247}
  ],
  spanish: [
    {maxWidth: 320, src: `${isNotQualified ? require('../images/books-carousel-spanish-mb-v2-1x.png') : require('../images/books-carousel-spanish-mb-v2-1x.webp')}`, alt: 'Disney, Frozen, Pixar, Valente, Marvel, Doctor Strange, Star Wars, Episodio V, Los colores, Los Gatos, Los Perros, Figuras en la playa, Los Delfines, Pedro el Pirata, Digger y Daisy, Lost Chicos del Vagon, digital books for kids', width: 228, height: 166},
    {maxWidth: 480, src: `${isNotQualified ? require('../images/books-carousel-spanish-mb-v2-2x.png') : require('../images/books-carousel-spanish-mb-v2-2x.webp')}`, alt: 'Disney, Frozen, Pixar, Valente, Marvel, Doctor Strange, Star Wars, Episodio V, Los colores, Los Gatos, Los Perros, Figuras en la playa, Los Delfines, Pedro el Pirata, Digger y Daisy, Lost Chicos del Vagon, digital books for kids', width: 366, height: 267},
    {maxWidth: 568, src: `${isNotQualified ? require('../images/books-carousel-spanish-mb-v2-3x.png') : require('../images/books-carousel-spanish-mb-v2-3x.webp')}`, alt: 'Disney, Frozen, Pixar, Valente, Marvel, Doctor Strange, Star Wars, Episodio V, Los colores, Los Gatos, Los Perros, Figuras en la playa, Los Delfines, Pedro el Pirata, Digger y Daisy, Lost Chicos del Vagon, digital books for kids', width: 430, height: 313},
    {maxWidth: 640, src: `${isNotQualified ? require('../images/books-carousel-spanish-dt-v2-1x.png') : require('../images/books-carousel-spanish-dt-v2-1x.webp')}`, alt: 'Disney, Frozen, Pixar, Valente, Marvel, Doctor Strange, Star Wars, Episodio V, Los colores, Los Gatos, Los Perros, Figuras en la playa, Los Delfines, Pedro el Pirata, Digger y Daisy, Lost Chicos del Vagon, digital books for kids', width: 647, height: 131},
    {maxWidth: 768, src: `${isNotQualified ? require('../images/books-carousel-spanish-dt-v2-2x.png') : require('../images/books-carousel-spanish-dt-v2-2x.webp')}`, alt: 'Disney, Frozen, Pixar, Valente, Marvel, Doctor Strange, Star Wars, Episodio V, Los colores, Los Gatos, Los Perros, Figuras en la playa, Los Delfines, Pedro el Pirata, Digger y Daisy, Lost Chicos del Vagon, digital books for kids', width: 781, height: 158},
    {maxWidth: 1024, src: `${isNotQualified ? require('../images/books-carousel-spanish-dt-v2-3x.png') : require('../images/books-carousel-spanish-dt-v2-3x.webp')}`, alt: 'Disney, Frozen, Pixar, Valente, Marvel, Doctor Strange, Star Wars, Episodio V, Los colores, Los Gatos, Los Perros, Figuras en la playa, Los Delfines, Pedro el Pirata, Digger y Daisy, Lost Chicos del Vagon, digital books for kids', width: 1037, height: 210},
    {maxWidth: 3000, src: `${isNotQualified ? require('../images/books-carousel-spanish-dt-v2-4x.png') : require('../images/books-carousel-spanish-dt-v2-4x.webp')}`, alt: 'Disney, Frozen, Pixar, Valente, Marvel, Doctor Strange, Star Wars, Episodio V, Los colores, Los Gatos, Los Perros, Figuras en la playa, Los Delfines, Pedro el Pirata, Digger y Daisy, Lost Chicos del Vagon, digital books for kids', width: 1220, height: 247}
  ]
};

export {
  imageConfig
};
