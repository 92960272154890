import {customElement} from '@aofl/element';
import {PaymentForm} from '@aoflmkt/payment-form';
import styles from './template.css';
import template from './template.js';
import {clickTrackerMixin} from '../../../../modules/click-tracker-mixin';
import {isMobileMixin} from '@aoflmkt/is-mobile-mixin';
import {ModalService} from '../modal-service';
import {modalsConfig} from './__config/modals';
import {accessibilityMixin} from '../../../../modules/accessibility-mixin';

const Mixin = isMobileMixin(clickTrackerMixin(accessibilityMixin(PaymentForm)));

/**
 * @summary ReadingIqPaymentForm
 * @class ReadingIqPaymentForm
 * @extends {AoflElement}
 */
@customElement('readingiq-payment-form')
class ReadingIqPaymentForm extends Mixin {
  /**
   *
   * Creates an instance of ReadingIqPaymentForm.
   */
  constructor() {
    super();
    ModalService.addModals(modalsConfig);
  }

  /**
   *
   * @readonly
   */
  static is = 'readingiq-payment-form';

  /**
   *
   */
  showCvvPopup() {
    ModalService.show('cvvCodeModal');
  }

  /**
   *
   */
  async connectedCallback() {
    super.connectedCallback();

    await this.updateComplete;
    this.enteractiveElements = this.shadowRoot.querySelectorAll('.enteractive-list-option');
    if (!this.enteractiveElements) return;

    this.enteractiveElements.forEach((elem) => {
      elem.addEventListener('keydown', (e) => {
        if (e.keyCode === 13) {
          elem.click();
        }
      });
    });
  }


  /**
   *
   * @return {Object}
   */
  render() {
    return super.render(template, [styles]);
  }

  /**
   *
   * @private
   */
  disconnectedCallback() {
    super.disconnectedCallback();
  }
}

export default ReadingIqPaymentForm;
