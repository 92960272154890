import styles from './template.css';
import template from './template';
import {AoflElement, customElement} from '@aofl/element';
import {isMobile} from '@aoflmkt/is-mobile';
import {storeInstance} from '@aofl/store/dist/legacy';
import {sdoNamespaces, eventNames} from '../../../../modules/constants-enumerate';
import {readingIqTrackerQueue} from '../../../../modules/readingiq-tracker-queue';

/**
 * @extends {AoflElement}
 */
@customElement('riq-video-disney')
class RiqVideoDisney extends AoflElement {
  /**
   * Creates an instance of RiqVideoDisney.
   */
  constructor() {
    super();
    this.addEventListener('click', (e) => e.stopPropagation()); // prevents replaying the video
  }

  /**
   * @readonly
   */
  static is = 'riq-video-disney';

  /**
   *
   */
  isMacOS() {
    const ua = window.navigator.userAgent;
    const macOS = ua.indexOf('Mac OS');

    if (macOS > 0) return true;
  }

  /**
   *
   */
  async play() {
    this.showOverlay();
    const macOS = this.isMacOS();
    if (isMobile.any && !macOS && !this.iosInApp) {
      this.requestFullscreen();
    }
    this.video.addEventListener('webkitendfullscreen', () => this.handleClose());
    await this.video.load();
    await this.video.play();
  }


  /**
   *
   */
  async handleClose() {
    this.video.pause();
    const macOS = this.isMacOS();
    await this.logVideoCompletionEvent(this.video.currentTime);
    this.video.currentTime = 0;
    this.hideOverlay();
    this.forceExitFullscreen();
    this.video.removeEventListener('webkitendfullscreen', this.handleClose);
    if (isMobile.any) {
      this.video.load();
    }
    if (macOS) {
      this.video.webkitExitFullScreen();
    }
  }

  /**
   *
   */
  showOverlay() {
    storeInstance.commit({
      namespace: sdoNamespaces.MAIN_LAYOUT,
      mutationId: 'freezeScroll',
      payload: {top: window.scrollY, left: window.scrollX}
    }, {
      namespace: sdoNamespaces.MAIN_LAYOUT,
      mutationId: 'setOverlay',
      payload: true
    });
    this.classList.add('active');
  }

  /**
   *
   */
  hideOverlay() {
    storeInstance.commit({
      namespace: sdoNamespaces.MAIN_LAYOUT,
      mutationId: 'setOverlay',
      payload: false
    }, {
      namespace: sdoNamespaces.MAIN_LAYOUT,
      mutationId: 'unFreezeScroll'
    });
    this.classList.remove('active');
  }

  /**
   * Stop the video to prevent mobile from viewing the video outside of fullscreen
   */
  handleFullscreenchange() {
    const isFullScreen = this.detectFullscreen();
    if (!isFullScreen) {
      this.handleClose();
    }
  }

  /**
   *
   */
  requestFullscreen() {
    const fns = [
      'requestFullscreen', 'mozRequestFullScreen',
      'webkitRequestFullscreen', 'msRequestFullscreen'
    ];

    for (let i = 0; i < fns.length; i++) {
      if (typeof this.video[fns[i]] === 'function') {
        this.video[fns[i]]();
        break;
      }
    }
  }

  /**
   *
   */
  detectFullscreen() {
    const fullscreenElements = ['fullscreenElement', 'mozFullScreenElement', 'webkitFullscreenElement', 'msFullscreenElement'];

    for (let i = 0; i < fullscreenElements.length; i++) {
      if (document[fullscreenElements[i]]) {
        return true;
      }
    }
    return false;
  }

  /**
   *
   */
  forceExitFullscreen() {
    const isFullScreen = this.detectFullscreen();
    if (!isFullScreen) {
      return;
    }
    if (document.exitFullscreen) {
      document.exitFullscreen();
    } else if (document.mozCancelFullScreen) {
      document.mozCancelFullScreen();
    } else if (document.webkitExitFullscreen) {
      document.webkitExitFullscreen();
    } else if (this.video.webkitExitFullScreen) {
      this.video.webkitExitFullScreen();
    }
  }

  /**
   *
   * @param {Object} res API res with payload of code_info and code
   */
  async logVideoCompletionEvent(videoCurrentTime) {
    let videoCompletion = Number(((videoCurrentTime / this.video.duration) * 100).toFixed(2));
    videoCompletion = Number.isNaN(videoCompletion) || !isFinite(videoCompletion) ? 0 : videoCompletion;
    try {
      await readingIqTrackerQueue.enqueueEvent({
        event_type: eventNames.VIDEO_PROGRESS,
        payload: {
          video_name: 'WRIQ',
          video_completion: videoCompletion
        }
      });
    } catch (e) {
    }
  }

  /**
   *
   */
  firstUpdated() {
    super.firstUpdated();
    this.video = this.video || this.shadowRoot.getElementById('riq-video');
    if (this.androidInApp) {
      this.video.muted = true;
    }
    this.video.onwebkitfullscreenchange = () => {
      this.handleFullscreenchange();
    };
  }

  /**
   * @return {Object}
   */
  render() {
    return super.render(template, [styles]);
  }
}

export default RiqVideoDisney;
