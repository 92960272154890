import {customElement} from '@aofl/element';
import {RegisterForm} from '@aoflmkt/register-form';
import styles from './template.css';
import template from './template.js';
import {clickTrackerMixin} from '../../../../modules/click-tracker-mixin';
import {isMobileMixin} from '@aoflmkt/is-mobile-mixin';

const Mixin = isMobileMixin(clickTrackerMixin(RegisterForm));

/**
 * @summary ReadingIqRegisterForm
 * @class ReadingIqRegisterForm
 * @extends {AoflElement}
 */
@customElement('readingiq-register-form')
class ReadingIqRegisterForm extends Mixin {
  /**
   *
   * Creates an instance of ReadingIqRegisterForm.
   */
  constructor() {
    super();
  }

  /**
   *
   * @readonly
   */
  static is = 'readingiq-register-form';

  /**
   *
   * @return {Object}
   */
  render() {
    return super.render(template, [styles]);
  }

  /**
   *
   * @private
   */
  disconnectedCallback() {
    super.disconnectedCallback();
  }
}

export default ReadingIqRegisterForm;
