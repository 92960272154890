import styles from './template.css';
import template from './template';
import {AoflElement, customElement} from '@aofl/element';
import {clickTrackerMixin} from '../../../../modules/click-tracker-mixin';
import {isMobileMixin} from '@aoflmkt/is-mobile-mixin';
import {ModalService} from '../modal-service';

/**
 * @summary DisneyFooter
 * @extends {AoflElement}
 */
@customElement('disney-footer')
class DisneyFooter extends isMobileMixin(clickTrackerMixin(AoflElement)) {
  /**
   * Creates an instance of DisneyFooter.
   */
  constructor() {
    super();
    this.currentYear = (new Date()).getFullYear();
  }

  /**
   * @readonly
   */
  static is = 'disney-footer';

  /**
   * @param {Event} e
   */
  showCookieConsentModal(e) {
    ModalService.show('cookieConsentModal');
  }

  /**
   *
   * @return {Object}
   */
  render() {
    return super.render(template, [styles]);
  }
}

export default DisneyFooter;
