import styles from './template.css';
import template from './template';
import {AoflElement, customElement} from '@aofl/element';
import {isMobileMixin} from '@aoflmkt/is-mobile-mixin';
import {clickTrackerMixin} from '../../../../modules/click-tracker-mixin';
import {accessibilityMixin} from '../../../../modules/accessibility-mixin';

/**
 * @summary ReadingIqModal
 * @class ReadingIqModal
 * @extends {AoflElement}
 */

@customElement('readingiq-modal')
class ReadingIqModal extends isMobileMixin(clickTrackerMixin(accessibilityMixin(AoflElement))) {
  /**
   * Creates an instance of ReadingIqModal.
   */
  constructor() {
    super();
  }

  /**
   * @readonly
   */
  static is = 'readingiq-modal';

  /**
   *
   * @return {Object}
   */
  render() {
    return super.render(template, [styles]);
  }
}

export default ReadingIqModal;
