import styles from './template.css';
import template from './template';
import {AoflElement, customElement} from '@aofl/element';
import {ModalService} from '../modal-service';
import {clickTrackerMixin} from '../../../../modules/click-tracker-mixin';
import {accessibilityMixin} from '../../../../modules/accessibility-mixin';

/**
 * @extends {AoflElement}
 */

@customElement('modal-close-button')
class ModalCloseButton extends clickTrackerMixin(accessibilityMixin(AoflElement)) {
  /**
   * Creates an instance of ModalCloseButton.
   */
  constructor() {
    super();
  }

  /**
   * @readonly
   */
  static is = 'modal-close-button';

  /**
   *
   */
  closeModal(e) {
    ModalService.hide();
  }

  /**
   * @return {Object}
   */
  render() {
    return super.render(template, [styles]);
  }
}

export default ModalCloseButton;

