const modalsConfig = {
  cvvCodeModal: {
    resolve: () => import('../modules/cvv-code-modal')
  }
};

export {
  modalsConfig
};

