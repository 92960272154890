export default (ctx, html) => html`
  <form id="register-form-container" name="register-form" @submit="${(e) => ctx.submitForm(e)}" novalidate>
    <fieldset>
      <div class="input-container ${ctx.form.email.valid ? '' : 'is-invalid'}">
        <label for="email">Email</label>
        <div class="input-wrapper">
          <input id="email" type="email" name="email" @blur="${(e) => ctx.validateEmail(e)}" @input="${(e) => ctx.updateEmail(e)}" value="${ctx.email}" autocomplete="email" aria-label="Enter your email address." aria-describedby="email-error-message" aria-invalid="${ctx.form.email.valid ? 'false' : 'true'}" required>
          ${ctx.form.email.isRequired.valid ? '' : html`
            <label id="email-error-message" class="error-message" for="email" role="alert status" aria-label="Error - Please enter an email address. It is a required field and must be filled in.">Please enter an email address.</label>
          `}
          ${ctx.form.email.isValidEmail.valid && ctx.form.email.isNotEmoji.valid ? '' : html`
            <label id="email-error-message" class="error-message" for="email" role="alert status" aria-label="Error - Please enter a valid email address. It is a required field and must be filled in.">Email format you entered is invalid.</label>
          `}
        </div>
      </div>
      <div class="input-container ${ctx.form.confirmEmail.valid ? '' : 'is-invalid'}">
        <label for="confirm-email">Confirm<span class="u-br"></span> Email</label>
        <div class="input-wrapper">
          <input id="confirm-email" type="email" name="confirm-email" @blur="${(e) => ctx.validateConfirmEmail(e)}" onpaste="return false" @input="${(e) => ctx.updateConfirmEmail(e)}" value="${ctx.confirmEmail}" autocomplete="email" aria-label="Confirm your email address." aria-describedby="confirm-email-error-message" aria-invalid="${ctx.form.confirmEmail.valid ? 'false' : 'true'}" required>
          ${ctx.form.confirmEmail.isRequired.valid ? '' : html`
            <label id="confirm-email-error-message" class="error-message" for="confirm-email" role="alert status" aria-label="Error - Please confirm your email address. It is a required field and must be filled in.">Please confirm your email.</label>
          `}
          ${ctx.form.confirmEmail.isEqual.valid ? '' : html`
            <label id="confirm-email-error-message" class="error-message" for="confirm-email" role="alert status" aria-label="Error - Email address do not match.">Email addresses do not match.</label>
          `}
        </div>
      </div>
      <div class="input-container ${ctx.form.password.valid ? '' : 'is-invalid'}">
        <label for="password">Password</label>
        <div class="input-wrapper">
          <input id="password" type="password" name="password" @blur="${(e) => ctx.validatePassword(e)}" @input="${(e) => ctx.updatePassword(e)}" value="${ctx.password}" autocomplete="new-password" aria-label="Enter your password." aria-describedby="password-error-message" aria-invalid="${ctx.form.password.valid ? 'false' : 'true'}" required>
          ${ctx.form.password.isRequired.valid ? '' : html`
            <label id="password-error-message" class="error-message" for="password" role="alert status" aria-label="Please enter a password.">Please enter a password.</label>
          `}
          ${ctx.form.password.minLength.valid ? '' : html`
            <label id="password-error-message" class="error-message" for="password" role="alert status" aria-label="Password must be longer than 5 characters.">Password must be longer than 5 characters.</label>
          `}
          ${ctx.form.password.isNotEmail.valid ? '' : html`
            <label id="password-error-message" class="error-message" for="password" role="alert status" aria-label="Password can't be an email address.">Password can't be an email address.</label>
          `}
          ${ctx.form.password.isNotAllDigits.valid ? '' : html`
            <label id="password-error-message" class="error-message" for="password" role="alert status" aria-label="Password can't have only digits.">Password can't have only digits.</label>
          `}
        </div>
      </div>
      <div class="input-container ${ctx.form.confirmPassword.valid ? '' : 'is-invalid'}">
        <label for="confirm-password">Confirm<span class="u-br"></span> Password</label>
        <div class="input-wrapper">
          <input id="confirm-password" type="password" name="confirm-password" @blur="${(e) => ctx.validateConfirmPassword(e)}" @input="${(e) => ctx.updateConfirmPassword(e)}" onpaste="return false;" value="${ctx.confirmPassword}" autocomplete="new-password" aria-label="Confirm your password." aria-describedby="confirm-password-error-message" aria-invalid="${ctx.form.confirmPassword.valid ? 'false' : 'true'}" required>
          ${ctx.form.confirmPassword.isRequired.valid ? '' : html`
            <label id="confirm-password-error-message" class="error-message" for="confirm-password" role="alert status" aria-label="Please confirm your password.">Please confirm your password.</label>
          `}
          ${ctx.form.confirmPassword.isEqual.valid ? '' : html`
            <label id="confirm-password-error-message" class="error-message" for="confirm-password" role="alert status" aria-label="Passwords do not match.">Passwords do not match.</label>
          `}
        </div>
      </div>
    </fieldset>
    <button id="register-submit-button" dom-scope="n0+jnS9p" type="submit" aria-hidden="true" tabindex="-1"></button>
  </form>
`;
