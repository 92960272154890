import {environment} from './constants-enumerate';
import {AppCallService} from '../modules/app-call-service';
import {readingIqTrackerQueue} from '../modules/readingiq-tracker-queue';

class BiEventService {
  /**
   *
   * @static
   */
  static loginEvent(apiResp) {
    if (environment.IN_APP) return; // don't log the login event; the mobile client will do it natively
    const username = apiResp.payload.username;

    try {
      readingIqTrackerQueue.enqueueEventV2({
        event_type: 'com.aofl.readingiq.user.login',
        payload: {
          username_type: /@/.test(username) ? 'email_address' : 'mobile_number',
          success: 'true',
          role: apiResp.payload.role
        },
        context: {
          user: {
            master_uuid: apiResp.payload.user_uuid,
            user_uuid: apiResp.payload.user_uuid,
            user_description: apiResp.payload.user_info.role[0].type
          }
        }
      });
    } catch {
      readingIqTrackerQueue.enqueueEventV2({
        event_type: 'com.aofl.readingiq.user.login',
        payload: {
          username_type: /@/.test(username) ? 'email_address' : 'mobile_number',
          success: 'false',
          role: null
        }
      });
    }
  }

  /**
   *
   * @static
   */
  static subscribeEvent(apiResp) {
    const trackingInfo = apiResp.payload.tracking;

    try {
      if (environment.IN_APP) {
        AppCallService.logBIEvent('com.aofl.readingiq.user.create', {
          created_user: {
            user_uuid: trackingInfo.user_uuid,
            type: trackingInfo.product_info.role,
            code: trackingInfo.endpoint_input.code_redeem || null,
            product_info: trackingInfo.product_info
          }
        });
      } else {
        readingIqTrackerQueue.enqueueEventV2({
          event_type: 'com.aofl.readingiq.user.create',
          payload: {
            created_user: {
              user_uuid: trackingInfo.user_uuid,
              type: trackingInfo.product_info.role,
              code: trackingInfo.endpoint_input.code_redeem || null,
              product_info: trackingInfo.product_info
            }
          },
          context: {
            user: {
              master_uuid: trackingInfo.user_uuid,
              user_uuid: trackingInfo.user_uuid,
              user_description: apiResp.payload.login_info.user_info.role[0].type
            }
          }
        });
      }
    } catch {
      return;
    }
  }
}

export {
  BiEventService
};
