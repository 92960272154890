import {storeInstance} from '@aofl/store/dist/legacy';
import {sdoNamespaces} from '../../../../../modules/constants-enumerate';

const sdo = {
  namespace: sdoNamespaces.HOME_TABS,
  mutations: {
    init() {
      return {
        pre: false,
        emerging: false,
        growing: true,
        independent: false,
        spanish: false
      };
    },
    /**
     *
     * @param {Object} subState
     * @param {Object} payload
     * @return {Function}
     */
    selectTab(subState, payload) {
      const nextState = {};
      for (const key in subState) {
        if (!Object.prototype.hasOwnProperty.call(subState, key)) {
          continue;
        }
        nextState[key] = false;
      }
      nextState[payload] = true;
      return Object.assign({}, subState, nextState);
    }
  }
};

storeInstance.addState(sdo);
