import styles from './template.css';
import template from './template';
import {AoflElement, customElement, property} from '@aofl/element';
import {storeInstance} from '@aofl/store/dist/legacy';

/**
 * @extends {AoflElement}
 */
@customElement('home-tab-content-disney')
class HomeTabContentDisney extends AoflElement {
  /**
   * Creates an instance of HomeTabContentDisney.
   */
  constructor() {
    super();
    this.storeInstance = storeInstance;
  }

  /**
   * @readonly
   */
  static is = 'home-tab-content-disney';

  @property({type: Boolean, attribute: true})
  active = false
  @property({type: String, attribute: true})
  swipeDirection = ''

  /**
   *
   */
  connectedCallback() {
    super.connectedCallback();
    this.addEventListener('transitionend', () => {
      this.classList.remove('sliding');
      if (!this.active) {
        this.classList.remove('active');
      }
    });
  }

  /**
   *
   * @param {*} name
   * @param {*} oldValue
   * @param {*} newValue
   */
  attributeChangedCallback(name, oldValue, newValue) {
    super.attributeChangedCallback(name, oldValue, newValue);

    if (name === 'active') {
      this.classList.add('sliding');
      if (this.active) {
        this.classList.add('active');
      } else {
        this.classList.remove('active');
      }
    }

    // if (name === 'active' && this.swipeDirection === 'swipeLeft') {
    //   this.classList.add('sliding-left');
    //   if (this.active) {
    //     this.classList.add('active');
    //   } else {
    //     this.classList.remove('active');
    //   }
    // }

    // if (name === 'active' && this.swipeDirection === 'swipeRight') {
    //   this.classList.add('sliding-right');
    //   if (this.active) {
    //     this.classList.add('active');
    //   } else {
    //     this.classList.remove('active');
    //   }
    // }
  }


  /**
   * @return {Object}
   */
  render() {
    return super.render(template, [styles]);
  }
}

export default HomeTabContentDisney;
