import '../../../../modules/link-to';
import {pages, cookies} from '../../../../modules/constants-enumerate';
import Cookies from 'js-cookie';

export default (ctx, html) => html`
  <span id="copyright">TM &amp; &copy; ${ctx.currentYear}
    <link-to dom-scope="1QBXf8Sk" id="aofl-link" href="https://www.ageoflearning.com/" target="_blank" use-parent-gate>Age of Learning, Inc. </link-to><span class="u-m-hide"> | </span>
  </span>
  <span id="links">
    <link-to dom-scope="2a+PpoMY" href="${pages.TANDC}" target="_blank" use-parent-gate>Terms &amp; Conditions</link-to> |
    <link-to dom-scope="cWqgzfOk" href="${pages.PRIVACY_POLICY}" target="_blank" use-parent-gate>Privacy Policy</link-to> |
    ${Cookies.get(cookies.REGION) !== 'CA' || Cookies.get(cookies.COUNTRY_CODE) !== 'US' ? '' : html`
      <a dom-scope="WdwsWnuL" id="sell-info" @click="${(e) => ctx.showCookieConsentModal(e)}">Do Not Sell My Personal Information</a>
      <span class="u-m-hide"> | </span>
    `}
    <link-to dom-scope="tINJWu8E" href="${pages.CUSTOMER_SUPPORT}" use-parent-gate>Customer Support</link-to>
  </span>
`;
