import styles from './template.css';
import template from './template';
import {AoflElement, customElement} from '@aofl/element';
import {isMobileMixin} from '@aoflmkt/is-mobile-mixin';
import {clickTrackerMixin} from '../../../../../modules/click-tracker-mixin';
import {accessibilityMixin} from '../../../../../modules/accessibility-mixin';

/**
 * @summary HomeDisneyFooter
 * @extends {AoflElement}
 */
@customElement('home-disney-footer')
class HomeDisneyFooter extends isMobileMixin(clickTrackerMixin(accessibilityMixin(AoflElement))) {
  /**
   * Creates an instance of HomeDisneyFooter.
   */
  constructor() {
    super();
  }

  /**
   *
   */
  connectedCallback() {
    super.connectedCallback();
  }

  /**
   * @readonly
   */
  static is = 'home-disney-footer';

  /**
   *
   * @return {Object}
   */
  render() {
    return super.render(template, [styles]);
  }
}

export default HomeDisneyFooter;
