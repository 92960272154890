import {deepAssign} from '@aofl/object-utils';
import {storeInstance} from '@aofl/store/dist/legacy';

/**
 * Strips the sdo key of all characters except digits.
 *
 * @param {String} namespace
 * @param {String} key
 * @return {Object}
 */
const digitsOnly = (namespace, key) => {
  return (nextState) => {
    const state = storeInstance.getState();
    if ((nextState[namespace] && nextState[namespace][key] && nextState[namespace][key] !== state[namespace][key])) {
      const input = nextState[namespace][key];
      const digitsOnly = input.replace(/\D/g, '');
      nextState = deepAssign(nextState, namespace, {[key]: digitsOnly});
    }
    return nextState;
  };
};

export {
  digitsOnly
};
